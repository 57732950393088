<template>
  <div>
    <!-- 表头 -->
    <div class="tinmceForm_img">
      <div class="tinymceSchedule_title">
        <p></p>
        <p>代发表详情</p>
      </div>
      <!-- 发放金额和成功金额 -->
      <div class="tinymceSchedule_money">
        <div class="tinymce_amountMoney">
          <p class="amountMoney_money">发放金额</p>
          <div class="amountMoney_number">
            <img src="../../assets/bgm/icon1.png" alt="" />
            <div class="amountMoney_text">
              <p>￥{{ sum.grantMon }}</p>
              <p>笔数：{{ sum.grantNum }}笔</p>
            </div>
          </div>
        </div>
        <div class="tinymce_successMoney">
          <p class="amountMoney_money">成功金额</p>
          <div class="amountMoney_number">
            <img src="../../assets/bgm/icon1.png" alt="" />
            <div class="amountMoney_text">
              <p>￥{{ sum.successMon }}</p>
              <p>笔数：{{ sum.successNum }}笔</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情数据框 -->
    <div class="tinmceForm_data">
      <div>
        <el-form label-width="110px">
          <el-form-item label="代发表名称：">
            {{ details.salaryName }}
          </el-form-item>
          <el-form-item label="支付完成状态：">
            {{ details.toBePaid }} / {{ details.grantNum }}
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-form label-width="100px">
          <el-form-item label="发薪企业：">
            {{ details.humanEntName }}
          </el-form-item>
          <el-form-item label="创建时间：">
            {{ details.createTime }}
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-form label-width="100px">
          <el-form-item label="用工企业：">
            {{ details.entName }}
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--输入框筛选  -->
    <div class="tinmceForm_input">
      <div class="PaymentInquiry">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="支付状态：">
            <el-select
              v-model="form.payStatus"
              placeholder=""
              @change="searchState"
              style="width: 100%"
              size="medium"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="PersonnelInquiry">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="人员查询：">
            <el-input
              v-model="form.personnelQuery"
              style="width: 100%"
              size="medium"
              placeholder="请输入姓名/手机号/身份证/银行卡"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button
          type="primary"
          size="medium"
          @click="inquire"
          style="margin-left: 10px; background: #6591f1"
        >
          <i class="el-icon-search" style="margin-right: 7px"></i
          >查询</el-button
        >
        <el-button
          @click="reset"
          size="medium"
          style="margin-left: 10px; background: #f7f8fa"
        >
          <i class="el-icon-refresh-left" style="margin-right: 7px"></i
          >重置</el-button
        >
      </div>
    </div>
    <!-- 按钮 -->
    <div class="tinmceForm_input tinmceForm_inputs">
      <span
        v-if="
          $store.state['user']['PermissionCodeList'].includes(
            'importPayrollTable'
          )
        "
      >
        <el-button
          class="button buttons"
          size="medium"
          @click="payoffTableShow"
          style="background: #6591f1"
          type="primary"
          icon="el-icon-upload2"
          v-if="details.status == 0 || details.status == 4"
          >导入代发表</el-button
        >
      </span>
      <span
        v-if="
          $store.state['user']['PermissionCodeList'].includes(
            'deriveIssuingDetailsBehalf'
          )
        "
      >
        <el-button
          class="button"
          @click="educeDerice"
          size="medium"
          style="background: #6591f1"
          type="primary"
          icon="el-icon-download"
          >导出</el-button
        >
      </span>
      <span
        v-if="
          $store.state['user']['PermissionCodeList'].includes(
            'exportPaymentVoucher'
          )
        "
      >
        <el-button
          class="button"
          size="medium"
          @click="voucher"
          style="background: #6591f1"
          type="primary"
          icon="el-icon-download"
          >导出付款凭证</el-button
        >
      </span>
      <span
        v-if="
          $store.state['user']['PermissionCodeList'].includes(
            'saveIssuingPersonnel'
          )
        "
      >
        <el-button
          class="button"
          size="medium"
          type="primary"
          style="background: #6591f1"
          icon="el-icon-circle-plus-outline"
          @click="newShow"
          v-if="details.status == 0 || details.status == 4"
          >新增人员</el-button
        >
      </span>
      <span
        v-if="
          $store.state['user']['PermissionCodeList'].includes(
            'deleteIssuingPersonnel'
          )
        "
      >
        <el-button
          class="button"
          size="medium"
          type="danger"
          icon="el-icon-close"
          v-if="details.status == 0 || details.status == 4"
          @click="deletes"
          >删除人员</el-button
        >
      </span>
    </div>
    <!-- 表单 -->
    <div class="tinmceForm_table">
      <el-table
        :data="pageList.list"
        style="width: 100%"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
        size="small"
        @selection-change="deletedData"
        :row-key="
          (row) => {
            return row.id;
          }
        "
      >
        <el-table-column type="selection" width="60" :selectable="isDisableds">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          align="left"
          show-overflow-tooltip
          width="80px"
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          align="left"
          show-overflow-tooltip
          width="80px"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="身份证号"
          align="left"
          show-overflow-tooltip
          min-width="100px"
        >
        </el-table-column>
        <el-table-column
          prop="accountNumber"
          label="银行卡号"
          align="left"
          show-overflow-tooltip
          min-width="100px"
        >
        </el-table-column>
        <el-table-column
          prop="grantMon"
          label="代发金额"
          align="left"
          show-overflow-tooltip
          width="100px"
        >
        </el-table-column>
        <el-table-column
          prop="payerName"
          label="支付人"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <span v-if="row.payerName !== null">
              <span>{{ row.payerName }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="finishTime"
          label="支付时间"
          align="left"
          show-overflow-tooltip
          min-width="100px"
        >
          <template slot-scope="{ row }">
            <span v-if="row.finishTime !== null">
              <span>{{ row.finishTime }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="payStatus"
          label="打款状态"
          align="left"
          show-overflow-tooltip
          min-width="100px"
        >
          <template slot-scope="{ row }">
            <el-tag
              v-if="row.payStatus == 'DEALING'"
              size="small"
              type="primary"
              >处理中</el-tag
            >
            <el-tag
              v-else-if="row.payStatus == 'SUCCESS'"
              size="small"
              type="success"
              >成功（
              <span
                style="cursor: pointer; color: #85ce61"
                @click="succeedFailure(row)"
                >打款回执单</span
              >
              ）</el-tag
            >
            <el-tag
              v-else-if="row.payStatus == 'FAIL'"
              size="small"
              type="danger"
              >失败</el-tag
            >
            <el-tag v-else-if="row.payStatus == null" size="small" type="info"
              >待打款</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="reason"
          label="失败原因"
          align="left"
          show-overflow-tooltip
          min-width="100px"
        >
          <template slot-scope="{ row }">
            <span v-if="row.reason !== null">
              <span style="color: #e84b4d">{{ row.reason }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="120px">
          <template v-slot="{ row, $index }">
            <span
              v-if="
                row.payStatus == 'DEALING' ||
                row.payStatus == 'SUCCESS' ||
                row.payStatus == 'FAIL' ||
                details.status == 1 ||
                details.status == 2 ||
                details.status == 3 ||
                !$store.state['user']['PermissionCodeList'].includes(
                  'amountModification'
                )
              "
              >-</span
            >
            <el-tag
              type="primary"
              @click="AmountModification(row, $index)"
              style="cursor: pointer"
              size="small"
              v-else
              >修改金额
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>
          共{{ this.pageList.total }} 条记录 第 {{ this.pageList.pageNum }} 页
        </p>
        <el-pagination
          background
          @size-change="currentChange"
          @current-change="changePage"
          :page-size="form.pageSize"
          :current-page="form.pageIndex"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="pageList.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="导入代发表"
      :visible.sync="payoffTable"
      width="540px"
      class="pop-up"
      :before-close="payoffTableShows"
    >
      <p class="line"></p>
      <div class="popContent">
        <div class="main">
          <div style="height: 30px">
            <div style="display: flex">
              <p class="Eclipse">下载工资表导入模板</p>
              <el-button
                type="primary"
                size="medium"
                style="background: #6591f1"
                @click="download"
              >
                点击下载</el-button
              >
            </div>
          </div>
          <div>
            <div style="margin: 20px 0px">上传工资表文件：</div>
            <el-upload
              class="upload-demo"
              ref="uploads"
              :drag="true"
              :multiple="true"
              action
              :show-file-list="true"
              :auto-upload="false"
              :file-list="formFileLists"
              :http-request="handleUploadForm"
              :on-change="handleChanges"
              :before-remove="beforeRemoves"
              accept=".xls,.xlsx"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">点击或将文件拖到这里上传</div>
              <div class="el-upload__text">支持扩展名： .xls .xlsx</div>
            </el-upload>
          </div>
          <div style="padding: 10px 0 20px 0">
            <span
              v-if="pageList.total != 0 && selectionRadio == true"
              style="
                color: #db142d;
                position: relative;
                left: -15px;
                top: 7px;
                font-size: 13px;
              "
              >*当前代发表中已存在人员，继续导入将完全覆盖</span
            >
          </div>
        </div>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          style="background: #6591f1"
          @click="ServiceImports"
          :disabled="loadingServiceImports"
          >导入</el-button
        >
        <el-button size="medium" @click="payoffTableShows">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="导入代发表"
      :visible.sync="ImportResult"
      width="740px"
      class="pop-up"
      :before-close="ImportResults1"
    >
      <p class="line"></p>
      <div class="popContent">
        <div class="consequence">
          <div>
            <img src="../../assets/bgm/accomplish.png" alt="" />
            <p>导入完成</p>
          </div>
          <div class="result">
            <span style="color: #6591f1">成功：{{ ImportList.yesNum }}</span>
            <span style="color: #6591f1"> | </span>
            <span style="color: #e84b4d">失败：{{ ImportList.noNum }}</span>
            <span style="color: #e84b4d"> / </span>
            <span style="color: #ff3745">失败原因如下</span>
          </div>
        </div>
        <div class="jhs_defeatedList">
          <el-table
            :data="ImportList.data"
            style="width: 100%"
            max-height="300"
            :header-cell-style="{ background: '#6591F1', color: '#fff' }"
          >
            <el-table-column
              prop="phone"
              width="150"
              align="left"
              label="手机号"
            >
            </el-table-column>
            <el-table-column prop="name" width="100" align="left" label="姓名">
            </el-table-column>
            <el-table-column prop="reason" align="left" label="失败原因">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="ImportResults1"
          type="primary"
          size="medium"
          style="margin-right: 30px; background: #6591f1"
          >完成导入</el-button
        >
        <el-button
          @click="ImportResults2"
          size="medium"
          style="margin-right: 30px"
          >继续导入</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="修改代发金额"
      :visible.sync="ModificationAmount"
      width="540px"
      class="pop-up"
      :before-close="ModificationMoneys"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form ref="forms" :model="forms" :rules="rules" label-width="100px">
          <el-form-item label="代发金额:" prop="grantMon">
            <el-input
              v-model="forms.grantMon"
              type="number"
              id="number"
              placeholder="请输入"
              size="medium "
              suffix-icon="el-icon-edit-outline"
              @blur="loseFocu()"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          style="background: #6591f1"
          @click="IssuingAmount"
          :disabled="moneyDisabled"
          >修改</el-button
        >
        <el-button size="medium" @click="ModificationMoneys">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增人员"
      :visible.sync="dialogShowAudit"
      width="540px"
      class="pop-up"
      :before-close="newClose"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form
          :model="forms"
          :rules="rules"
          ref="forms"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名:" prop="name">
            <el-input
              v-model.trim="forms.name"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号:" prop="number">
            <el-input
              v-model.trim="forms.number"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号:" prop="phone">
            <el-input
              v-model.trim="forms.phone"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行卡号:" prop="accountNumber">
            <el-input
              v-model.trim="forms.accountNumber"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="代发金额:" prop="grantMon">
            <el-input
              id="number"
              type="number"
              style="margin-top: 5px"
              v-model.trim="forms.grantMon"
              placeholder="请输入"
              maxlength="10"
              size="medium"
              @blur="loseFocus()"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          style="background: #6591f1"
          @click="newEnterprise"
          :disabled="present"
          >新增</el-button
        >
        <el-button size="medium" @click="newClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="导出付款凭证"
      :visible.sync="dialogVisible"
      width="540px"
      class="pop-up"
      :before-close="vouchers"
    >
      <p class="line"></p>
      <div class="popContent">
        <div style="height: 100px; line-height: 70px">
          <el-radio-group v-model="radio">
            <el-radio :label="1">导出银行回单</el-radio>
            <el-radio :label="2">导出企业回单</el-radio>
          </el-radio-group>
        </div>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          style="background: #6591f1"
          @click="voucherDerice"
          >确定</el-button
        >
        <el-button size="medium" @click="vouchers">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { instance } from "@/utils/request";
import bus from "@/api/bus";
export default {
  data() {
    return {
      loadingTable: false,
      present: false,
      form: {
        pageIndex: 1,
        pageSize: 15,
        payStatus: null,
        personnelQuery: "",
        replaceProvideId: "",
      },
      details: "",
      pageList: "",
      dialogShowAudit: false,
      deploy: false,
      relevancy: false,
      payment: false,
      companyDetails: "",
      textarea: "",
      radioTreaty: 2,
      isTimerSrop: false, // 验证码
      msgCodeBtnText: 0,
      phoneCode: "",
      times: "",
      timers: null,
      payTiem: "",
      foundTiem: "",
      activeIndex: null,
      account: "",
      payoffTable: false,
      selectionRadio: true,
      loadingServiceImports: false,
      ImportResult: false,
      ModificationAmount: false,
      moneyDisabled: false,
      dialogVisible: false,
      sum: "",
      formFileLists: [],
      ImportList: "",
      saveId: [],
      yxData: [],
      radio: 1,
      timeData: "",
      delivery: true,
      forms: {
        accountNumber: "",
        id: "",
        name: "",
        number: "",
        phone: "",
        grantMon: "",
      },
      options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "DEALING",
          label: "处理中",
        },
        {
          value: "SUCCESS",
          label: "支付成功",
        },
        {
          value: "FAIL",
          label: "支付失败",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入新增人员姓名",
            trigger: ["blur"],
          },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z]{2,36}$/,
            message: "只能输入中/英文且长度在2到36之间",
            trigger: ["blur"],
          },
        ],
        number: [
          {
            required: true,
            message: "请输入新增人员身份证号",
            trigger: ["blur"],
          },
          {
            pattern:
              /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "身份证号码格式不正确",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入新增人员手机号",
            trigger: ["blur"],
          },
          {
            max: 11,
            message: "手机号码长度最多不能超过11位",
            trigger: ["blur"],
          },
          {
            pattern:
              /^((13[0-9])|(14[0-9])|(15[0-9])|(17[0-9])|(18[0-9]))\d{8}$/,
            message: "请输入正确的手机号",
            trigger: ["blur"],
          },
        ],
        accountNumber: [
          {
            required: true,
            message: "请输入新增人员银行卡号",
            trigger: ["blur"],
          },
          {
            pattern: /^[0-9]{1,50}$/,
            message: "只能输入数字且长度在1到50之间",
            trigger: ["blur"],
          },
        ],
        grantMon: [
          {
            required: true,
            message: "请输入代发金额",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  mounted() {
    this.lines();
    this.lines1();
    this.lines2();
    this.lines3();
    this.lines4();
    this.getData();
    bus.$on("sends", () => {
      this.getData();
    });
  },
  methods: {
    //点击导出
    educeDerice() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.toExportExcel();
      }, 500);
    },
    toExportExcel() {
      const { form } = this;
      const { pageIndex, pageSize, payStatus, personnelQuery } = form;
      instance({
        method: "POST",
        url: "/v1/xSalaryDetails/getSalaryDetailsWrite",
        data: {
          pageIndex,
          pageSize,
          payStatus,
          personnelQuery,
          replaceProvideId: this.$route.query.id,
        },
        responseType: "blob",
      }).then((res) => {
        const link = document.createElement("a"); //创建a标签
        let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // response就是接口返回的文件流
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "代发详情明细列表"; // 自定义文件名
        link.click(); // 下载文件
        URL.revokeObjectURL(objectUrl); // 释放内存
      });
    },
    lines() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[0].prepend(lswt_2);
    },
    lines1() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[1].prepend(lswt_2);
    },
    lines2() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[2].prepend(lswt_2);
    },
    lines3() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[3].prepend(lswt_2);
    },
    lines4() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[4].prepend(lswt_2);
    },
    getData() {
      this.loadingTable = true;
      setTimeout(() => {
        this.loadingTable = false;
      }, 60000);
      const { form } = this;
      const { pageIndex, pageSize, payStatus, personnelQuery } = form;
      instance({
        method: "post",
        url: "/v1/xReplaceProvide/salaryDetailsList",
        data: {
          pageIndex,
          pageSize,
          payStatus,
          personnelQuery,
          replaceProvideId: this.$route.query.id,
        },
      })
        .then((res) => {
          if (res.success) {
            this.loadingTable = false;
            this.details = res.data.PayrollInfoVO;
            bus.$emit("send", JSON.stringify(this.details));
            this.pageList = res.data.list;
            this.sum = res.data.salaryDetailsVO;
            for (const item of this.pageList.list) {
              item.grantMon = Number(item.grantMon).toFixed(2);
            }
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 筛选方法
    // 状态
    searchState(val) {
      this.form.pageIndex = 1;
      this.form.payStatus = val;
      this.getData();
    },
    // 查询
    inquire() {
      this.form.pageIndex = 1;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 重置页面
    reset() {
      this.form.payStatus = null;
      this.form.personnelQuery = "";
      this.form.replaceProvideId = "";
      this.form.pageIndex = 1;
      this.form.pageSize = 15;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 翻页
    changePage(val) {
      this.form.pageIndex = val;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // f分页
    currentChange(val) {
      this.form.pageSize = val;
      this.form.pageIndex = 1;
      this.getData();
    },
    newShow() {
      this.forms.name = "";
      this.forms.number = "";
      this.forms.phone = "";
      this.forms.accountNumber = "";
      this.forms.grantMon = "";
      this.forms.id = "";
      this.dialogShowAudit = true;
    },
    newClose() {
      this.dialogShowAudit = false;
    },
    relevancyShow(row) {
      this.companyDetails = row;
      this.relevancy = true;
    },
    relevancyClose() {
      this.relevancy = false;
    },
    configurationshow(row) {
      this.forms.familyName = "";
      this.forms.account = "";
      this.forms.becif = "";
      this.forms.entCode = "";
      this.forms.expenditureCode = "";
      this.forms.unitCode = "";
      this.entId = row.id;
      this.deploy = true;
    },
    configurationClose() {
      this.deploy = false;
    },
    paymentShow() {
      this.payment = true;
    },
    paymentClose() {
      this.payment = false;
    },
    newEnterprise() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.$refs.forms.validate((valid) => {
          if (valid) {
            this.present = true;
            setTimeout(() => {
            this.present = false;
          }, 60000);
            const { forms } = this;
            const { name, number, phone, accountNumber, grantMon } = forms;
            instance({
              method: "post",
              url: "/v1/xSalaryDetails/createPersonnel",
              data: {
                name,
                number,
                phone,
                accountNumber,
                grantMon,
                id: this.$route.query.id,
              },
            })
              .then((res) => {
                if (res.code === 200) {
                  this.getData();
                  this.newClose();
                  this.dialogShowAudit = false;
                  setTimeout(() => {
                    this.present = false;
                  }, 1000);
                }else{
                  this.present = false;
                }
              })
              .catch((err) => {
                this.present = false;
              });
          }
        });
      }, 500);
    },
    // 下载文件
    download() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.load =
          "https://enclosure.jinhuasuan.cn/file/excel/payroll_import_template.xlsx";
        window.location.href = this.load;
      }, 500);
    },
    payoffTableShow() {
      this.formFileLists = [];
      this.payoffTable = true;
    },
    payoffTableShows() {
      this.payoffTable = false;
      this.selectionRadio = true;
    },
    // 工资表导入
    handleUploadForm(param) {
      this.loadingServiceImports = true;
      setTimeout(() => {
            this.loadingServiceImports = false;
          }, 60000);
      const formData = new FormData();
      formData.append("replaceProvideId", this.$route.query.id); // 额外参数
      formData.append("file", param.file);
      formData.append("overwrite", this.selectionRadio);
      instance({
        method: "post",
        url: "/v1/xSalaryDetails/importPayroll",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      }).then((res) => {
        if (res.code === 200) {
          this.ImportList = res.data;
          this.payoffTableShows();
          this.ImportResults();
          this.getData();
          this.$message.success("上传文件成功");
          this.payoffTable = false;
          setTimeout(() => {
            this.loadingServiceImports = false;
          }, 1000);
        } else {
          this.formFileLists = [];
          this.loadingServiceImports = false;
        }
      });
    },
    handleChanges(files, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    beforeRemoves(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    ServiceImports() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.$refs.uploads.submit();
      }, 500);
    },
    // 弹窗显示
    ImportResults() {
      this.formFileLists = [];
      this.ImportResult = true;
    },
    ImportResults1() {
      this.ImportResult = false;
      this.formFileLists = [];
      this.selectionRadio = true;
      this.getData();
    },
    ImportResults2() {
      this.ImportResult = false;
      this.selectionRadio = false;
      this.payoffTableShow();
    },
    // 修改代发金额
    AmountModification(row, $index) {
      this.ModificationAmount = true;
      this.row = row;
      this.index = $index;
    },
    ModificationMoneys() {
      this.ModificationAmount = false;
      this.forms.grantMon = "";
    },
    // 修改代发金额
    IssuingAmount() {
      this.$refs.forms.validate(async (valid) => {
        if (valid) {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.moneyDisabled = true;
        this.forms.grantMon = Number(this.forms.grantMon).toFixed(2);
        instance({
          method: "post",
          url: "/v1/xSalaryDetails/updateAmount",
          data: {
            id: this.row.id,
            grantMon: this.forms.grantMon,
          },
        })
          .then((res) => {
            this.getData();
            this.ModificationMoneys();
            this.$refs.multipleTable.clearSelection();
            this.ModificationAmount = false;
            setTimeout(() => {
                  this.moneyDisabled = false;
                }, 1000);
          })
          .catch((err) => {
            this.moneyDisabled = false;
          });
      }, 500);
    }
      });
    },
    loseFocu() {
      if (Number(this.forms.grantMon) >= 99999999) {
        this.$message.error("代发金额不能大于99999999！");
        this.moneyDisabled = true;
      } else {
        this.moneyDisabled = false;
        this.forms.grantMon = Number(this.forms.grantMon).toFixed(2);
      }
    },
    // 任务金额  失去焦点
    loseFocus() {
      this.forms.grantMon = Number(this.forms.grantMon).toFixed(2);
    },
    // 获取选中项 限制多选人数500
    deletedData(val) {
      this.saveId = [];
      this.yxData = val;
      if (val.length > 500) {
        const del_row = val.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
      for (let i = 0; i < this.yxData.length; i++) {
        this.saveId.push(this.yxData[i].id);
      }
    },
    isDisableds(item) {
      if (item !== null) {
        if (item.payStatus === null) {
          return 1;
        }
        return 0;
      }
    },
    deletes() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        if (this.saveId.length > 0) {
        this.$confirm("此操作将永久删除该收款人, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
              instance({
                method: "post",
                url: "/v1/xSalaryDetails/deletePayrollPerson",
                data: [...this.saveId],
              })
                .then((res) => {
                  if (res.code == 200) {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.getData();
                    this.$refs.multipleTable.clearSelection();
                  }
                })
                .catch((err) => {});
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
            } else {
              this.$message.error("最少选中一位");
            }
      }, 500);
    },
    // 成功回单
    succeedFailure(row) {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        instance({
          method: "post",
          url: `/v1/xSalaryDetails/sameDayHistoryReceiptDataQuery/${row.id}`,
          responseType: "blob",
        })
          .then((res) => {
            if (res.type == "application/json") {
              this.$message.warning("暂无打款回执单");
            } else {
              var link = document.createElement("a"); //a标签下载
              let blob = new Blob([res], { type: "application/pdf" }); // response就是接口返回的文件流
              link.href = window.URL.createObjectURL(blob);
              window.open(link.href, "_blank");
            }
          })
          .catch((err) => {});
      }, 500);
    },
    voucher() {
      this.dialogVisible = true;
    },
    vouchers() {
      this.dialogVisible = false;
      this.radio = 1;
    },
    //点击导出
    voucherDerice() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.toWord();
      }, 500);
    },
    toWord() {
      this.timeData = new Date();
      instance({
        method: "POST",
        url: `/v1/xSalaryDetails/getObtainPaymentVoucher/${this.$route.query.id}/${this.radio}`,
        responseType: "blob",
      }).then((res) => {
        const link = document.createElement("a"); //创建a标签
        let blob;
        // if(this.radio == 1){
        //   blob = new Blob([res], { type: "application/msword" }); // response就是接口返回的文件流
        // }else{
        //   blob =  new Blob([res], { type: "application/pdf" }); // response就是接口返回的文件流
        // }
        blob = new Blob([res], { type: "application/pdf" });
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        // if(this.radio == 1){
        //   link.download = this.details.salaryName + '-付款凭证-工资明细凭证-' + this.timeData.toISOString() + '.doc' ; // 自定义文件名
        // }else{
        //   link.download = this.details.salaryName + '-付款凭证-工资明细凭证-' + this.timeData.toISOString() + '.pdf' ; // 自定义文件名
        // }
        link.download =
          this.details.salaryName +
          "-付款凭证-工资明细凭证-" +
          this.timeData.toISOString() +
          ".pdf";
        link.click(); // 下载文件
        URL.revokeObjectURL(objectUrl); // 释放内存
        this.vouchers()
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tinmceForm_img {
  width: 100%;
  height: 317px;
  background-color: #fff;
  padding: 20px;
  .tinymceSchedule_title {
    width: 100%;
    border-bottom: 1px solid #ececec;
    display: flex;
    height: 40px;
    p:first-child {
      width: 3px;
      height: 13px;
      background: #6591f1;
      margin-top: 5px;
    }
    p:last-child {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 10px;
    }
  }
  .tinymceSchedule_money {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .tinymce_successMoney {
    width: 356px;
    height: 174px;
    background: url("../../assets/bgm/successimg.png");
    background-size: cover;
    margin-left: 30px;
  }
  .tinymce_amountMoney {
    width: 356px;
    height: 174px;
    background: url("../../assets/bgm/amountImg.png");
    background-size: cover;
    margin-left: 30px;
  }
  .amountMoney_money {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    margin-left: 20px;
  }
  .amountMoney_number {
    margin-left: 20px;
    display: flex;
    img {
      width: 54px;
      height: 54px;
      margin-top: 60px;
    }
  }
  .amountMoney_text {
    margin-left: 10px;
    margin-top: 65px;
    p:first-child {
      width: 59px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    p:last-child {
      height: 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.tinmceForm_data {
  width: 100%;
  height: 120px;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
}
.tinmceForm_input {
  width: 100%;
  height: 96px;
  background-color: #fff;
  padding: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .button {
    margin-left: 30px;
  }
  .buttons {
    margin-left: 0px;
  }
  .PaymentInquiry {
    /deep/ .el-form-item__content {
      width: 180px;
    }
  }
  .PersonnelInquiry {
    /deep/ .el-form-item__content {
      width: 240px;
    }
  }
}
.tinmceForm_inputs {
  justify-content: flex-start;
}
.tinmceForm_table {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  margin-top: 20px;
  /deep/ .el-table {
    thead {
      height: 50px;
      background: #6591f1;
      font-weight: 600;
    }
    .el-table__cell.is-center {
      text-align: left;
    }
    .cell {
      padding-left: 30px;
    }
  }
  /deep/ .el-tag {
    background: rgba(101, 145, 241, 0.12);
    color: #6591f1;
  }
  /deep/ .el-tag--danger {
    background: rgba(232, 75, 77, 0.12);
    color: #e84b4d;
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}
.pop-up {
  .line {
    height: 1px;
    background: #ececec;
    margin: 10px 0 30px;
  }
  .lines {
    height: 1px;
    background: #ececec;
  }
  /deep/ .el-dialog__title {
    padding-left: 14px;
    // border-left: #6591F1 3px solid;
    font-size: 16px;
  }
  /deep/ .el-form-item__label {
    font-weight: 400;
    color: #666666;
  }
  /deep/ .el-button {
    font-size: 12px;
    font-weight: 400;
    color: #999;
  }
  /deep/ .el-button--primary {
    color: #fff;
  }
  .createForm_button {
    /deep/ .el-button--primary {
      font-size: 14px;
    }
  }
  /deep/ .has-gutter {
    .cell {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      padding-left: 30px;
    }
  }
  /deep/ .el-dialog__body {
    padding: 0px 40px 20px;
  }
  /deep/ .el-dialog__footer {
    text-align: left;
    padding: 10px 40px 40px;
  }
  /deep/ .el-dialog__header {
    padding: 40px 40px 10px;
    .el-dialog__headerbtn {
      top: 40px;
      right: 40px;
    }
  }
  .popContent {
    padding: 0 26px 0 30px;
    .consequence {
      height: 200px;
      display: flex;
      justify-content: center;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        text-align: center;
      }
      .result {
        line-height: 100px;
      }
      .jhs_defeatedList {
        width: 100%;
        height: 330px;
        position: relative;
        top: 30px;
        /deep/ .el-table {
          ::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
  .popContents {
    padding: 0px;
    .arguments {
      padding-left: 30px;
      p {
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
      }
    }
    .components-forms {
      padding: 0;
      .pagination {
        margin: 20px 0;
      }
    }
  }
  .Eclipse {
    background: #f7f9fe;
    height: 36px;
    margin-right: 22px;
    padding: 8px 30px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  /deep/ .el-input-group__append {
    background: #e2e2e2;
    border: none;
    font-size: 12px;
    font-weight: 400;
    color: #666660;
    width: 84px;
    height: 36px;
    padding: 9px 36px;
  }
}
/deep/ .el-input__inner {
  background: #f7f8fa;
  border: none;
}
/deep/ .el-button--danger {
  background: #e84b4d;
}
/deep/ #number {
  appearance: none !important;
}
/deep/ input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
