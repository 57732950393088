<template>
  <div class="tinymce_details_content">
   <!-- 左边进度表 -->
   <el-row :gutter="20">
    <el-col :span="6">
      <div class="grid-content bg-purple">
         <TinymceSchedule></TinymceSchedule>
      </div>
    </el-col>
    <el-col :span="18">
      <div class="grid-content bg-purple">
        <TinymceForm></TinymceForm>
      </div>
    </el-col>
  </el-row>

  </div>
</template>

<script>
import TinymceSchedule from '../../../components/Tinymce/tinymceSchedule.vue'
import TinymceForm from '../../../components/Tinymce/tinmceForm.vue'
export default {
  name: 'tinymce_details',
  components: { 
    TinymceSchedule, 
    TinymceForm 
    }
}
</script>

<style lang="less" scoped>
.tinymce_details_content {
  padding: 20px;
  // min-width: 1400px;
  min-width: 1220px;
}

</style>