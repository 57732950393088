<template>
  <div class="tinymceSchedule_content">
    <!-- 表头 -->
    <div class="tinymceSchedule_title">
      <p></p>
      <p>发放进度</p>
    </div>
    <!-- 进度栏 -->
    <div class="timeline" id="timeline">
      <div>
        <div v-if="this.activities.length == 0" class="noData">
          <img src="@/assets/bgm/wudata.png" alt="" />
        </div>
        <el-timeline v-else>
          <el-timeline-item
            v-for="(activity, index) in this.activities"
            :key="index"
            size="large"
            icon="el-icon-more"
            color="#6591F1"
          >
            <div class="timelinetitle">
              <P v-if="activity.status == 0">
                <img src="@/assets/payrollCredit/05.png" alt="" />
                <span> 创建代发表</span>
              </P>
              <P v-else-if="activity.status == 1">
                <img src="@/assets/payrollCredit/06.png" alt="" />
                <span> 提交代发表</span>
              </P>
              <P v-else-if="activity.status == 2 || activity.status == 4">
                <img src="@/assets/payrollCredit/07.png" alt="" />
                <span> 代发审核</span>
              </P>
              <P v-else-if="activity.status == 3">
                <img src="@/assets/payrollCredit/08.png" alt="" />
                <span> 支付发放</span>
              </P>
            </div>
            <div
              class="timelineCon"
              v-if="
                index !== activities.length - 1 ||
                (activity.status == 3 && activity.id)
              "
            >
              <p>
                <img src="@/assets/payrollCredit/01.png" alt="" />
                <span> {{ activity.createTime }}</span>
              </p>
              <p>
                <img src="@/assets/payrollCredit/02.png" alt="" />
                <span> {{ activity.userName }}</span>
              </p>
              <p v-if="activity.status == 2 || activity.status == 4">
                <img src="@/assets/payrollCredit/04.png" alt="" />
                <span v-if="activity.status == 2" style="color: #6591f1">
                  审核通过</span
                >
                <span v-else-if="activity.status == 4" style="color: #e84b4d">
                  驳回</span
                >
              </p>
              <p v-if="activity.status != 0">
                <img src="@/assets/payrollCredit/03.png" alt="" />
                <span class="textFormatting">
                  说明：{{ activity.remarks }}</span
                >
              </p>
            </div>
            <div
              class="btn"
              v-if="
                index === activities.length - 1 &&
                activity.status == 1 &&
                details.grantNum != 0 &&
                $store.state['user']['PermissionCodeList'].includes(
                  'submitSalaryPaymentBehalf'
                )
              "
            >
              <el-button
                type="primary"
                size="medium"
                style="background: #6591f1; width: 100%"
                @click="relevancyShow"
                >提交审核</el-button
              >
            </div>
            <div
              class="btn"
              v-if="
                index === activities.length - 1 &&
                activity.status == 2 &&
                details.grantNum != 0 &&
                $store.state['user']['PermissionCodeList'].includes(
                  'toExamineSalaryPaymentBehalf'
                )
              "
            >
              <el-button
                type="primary"
                size="medium"
                style="background: #6591f1; width: 100%"
                @click="configurationshow"
                >审 核</el-button
              >
            </div>
            <div
              class="btn"
              v-if="
                index === activities.length - 1 &&
                activity.status == 3 &&
                !activity.userId &&
                details.grantNum != 0 &&
                $store.state['user']['PermissionCodeList'].includes(
                  'paymentBehalf'
                )
              "
            >
              <el-button
                type="primary"
                size="medium"
                style="background: #6591f1; width: 100%"
                @click="paymentShow"
                >支 付</el-button
              >
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <el-button
      style="width: 100%; background: #6591f1"
      type="primary"
      size="medium"
      >代发表备注</el-button
    >
    <!-- 备注留言板 -->
    <div class="comment">
      <div class="commentMain">
        <div class="commentMainBody">
          <div v-if="this.remarksList.length == 0" class="noData">
            <img src="@/assets/bgm/wudata.png" alt="" />
          </div>
          <div v-else>
            <div
              v-for="(activity, index) in this.remarksList"
              :key="index"
              style="margin-bottom: 20px"
            >
              <div class="leaveWord">
                <div>
                  <img src="@/assets/payrollCredit/03.png" alt="" />
                  <span class="staff"> {{ activity.userName }}</span>
                </div>
                <span
                  v-if="employee == 'true'"
                  @click="messageDelete(activity)"
                  class="expurgate"
                  >删除</span
                >
              </div>
              <div class="content textFormatting">
                留言： {{ activity.remarks }}
              </div>
            </div>
          </div>
        </div>
        <div class="messageButton">
          <el-button
            type="primary"
            size="medium"
            style="width: 100%; background: #6591f1"
            @click="CommentOfMessage"
            >留 言</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      title="提交审核"
      :visible.sync="relevancy"
      width="540px"
      class="pop-up"
      :before-close="relevancyClose"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form ref="forms" label-width="130px">
          <el-form-item label="代发表名称:" prop="entName">
            <span>{{ details.salaryName }}</span>
          </el-form-item>
          <el-form-item label="发薪企业：">
            <span>{{ details.humanEntName }}</span>
          </el-form-item>
          <el-form-item label="用工企业：">
            <span>{{ details.entName }}</span>
          </el-form-item>
          <el-form-item label="用工企业账户余额:">
            <span>{{ this.account.balance }} 元</span>
          </el-form-item>
          <el-form-item label="代发总人数：">
            <span>{{ paidList.grantNum }} 人</span>
          </el-form-item>
          <el-form-item label="代发总金额：">
            <span>{{ paidList.grantMon }} 元</span>
          </el-form-item>
          <el-form-item label="说明:">
            <el-input
              type="textarea"
              :rows="6"
              maxlength="30"
              show-word-limit
              placeholder="请输入内容"
              v-model="forms.remarks"
              style="width: 300px"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="submit"
          :disabled="present"
          style="background: #6591f1"
          >提交</el-button
        >
        <el-button size="medium" @click="relevancyClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="审核"
      :visible.sync="deploy"
      width="540px"
      class="pop-up"
      :before-close="configurationClose"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form ref="forms" label-width="130px">
          <el-form-item label="代发表名称:" prop="entName">
            <span>{{ details.salaryName }}</span>
          </el-form-item>
          <el-form-item label="发薪企业：">
            <span>{{ details.humanEntName }}</span>
          </el-form-item>
          <el-form-item label="用工企业：">
            <span>{{ details.entName }}</span>
          </el-form-item>
          <el-form-item label="用工企业账户余额:">
            <span>{{ this.account.balance }} 元</span>
          </el-form-item>
          <el-form-item label="代发总人数：">
            <span>{{ paidList.grantNum }} 人</span>
          </el-form-item>
          <el-form-item label="代发总金额：">
            <span>{{ paidList.grantMon }} 元</span>
          </el-form-item>
        </el-form>
      </div>
      <p class="line"></p>
      <div>
        <el-form ref="forms" label-width="130px">
          <el-form-item label="审核状态:">
            <el-radio-group v-model="radioTreaty">
              <el-radio :label="2">审核通过</el-radio>
              <el-radio :label="4">驳回</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="说明:">
            <el-input
              type="textarea"
              :rows="6"
              maxlength="30"
              show-word-limit
              placeholder="请输入内容"
              v-model="forms.remarks"
              style="width: 300px"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="examine"
          :disabled="audit"
          style="background: #6591f1"
          >提交</el-button
        >
        <el-button size="medium" @click="configurationClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="留言"
      :visible.sync="CommentMessage"
      width="540px"
      class="pop-up"
      :before-close="CommentOfMessages"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form ref="forms" :model="forms" :rules="rules" label-width="100px">
          <el-form-item label="留言备注:" prop="remarks">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="forms.remarks"
              style="width: 300px"
              maxlength="500"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="addLeaveWord"
          style="background: #6591f1"
          >提交</el-button
        >
        <el-button size="medium" @click="CommentOfMessages">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="支付打款"
      :visible.sync="payment"
      width="540px"
      class="pop-up"
      :before-close="paymentClose"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form ref="forms" :model="forms" :rules="rules" label-width="130px">
          <el-form-item label="代发表名称:">
            <span>{{ details.salaryName }}</span>
          </el-form-item>
          <el-form-item label="发薪企业：">
            <span>{{ details.humanEntName }}</span>
          </el-form-item>
          <el-form-item label="用工企业：">
            <span>{{ details.entName }}</span>
          </el-form-item>
          <el-form-item label="用工企业账户余额:">
            <span>{{ this.account.balance }} 元</span>
          </el-form-item>
          <el-form-item label="代发总人数：">
            <span>{{ paidList.grantNum }} 人</span>
          </el-form-item>
          <el-form-item label="代发总金额：">
            <span>{{ paidList.grantMon }} 元</span>
          </el-form-item>
          <el-form-item label="支付密码：" prop="code">
            <el-input
              size="small"
              show-password
              placeholder="请输入支付密码"
              v-model.trim="forms.code"
              style="width: 200px"
            >
            </el-input>
            <!-- <el-button
              style="margin-left: 10px;background: #6591f1;width: 34%;"
              @click="send"
              v-if="!isTimerSrop"
              size="small"
              type="primary"
              >获取验证码</el-button
            >
            <el-button  size="small" style="margin-left: 20px" disabled v-else>{{
              msgCodeBtnText
            }}</el-button> -->
          </el-form-item>
          <el-form-item label="支付附言:">
            <el-input
              v-model="forms.remark"
              style="width: 200px"
              type="text"
              placeholder="请输入支付附言"
              maxlength="30"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="SubmitAuditPay"
          :disabled="toLoad"
          style="background: #6591f1"
          >立即支付</el-button
        >
        <el-button size="medium" :disabled="toLoadClose" @click="paymentClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { instance } from "@/utils/request";
import bus from "@/api/bus";
import { Encrypt, Decrypt } from "@/utils/secret.js";
export default {
  data() {
    return {
      toLoad: false,
      present: false,
      audit: false,
      toLoadClose: false,
      textarea1: "",
      activities: [],
      remarksList: [],
      details: "",
      timers: null,
      SubmitForReview: false,
      SubmitLoading: false,
      auditLoading: false,
      relevancy: false,
      deploy: false,
      CommentMessage: false,
      ProjectAccountBalance: "",
      explain: "",
      textarea: "",
      radioTreaty: 2,
      account: "",
      payment: false,
      // isTimerSrop: false, // 验证码
      // time: 60,
      // timer:null,
      // msgCodeBtnText: 0,
      paidList: "",
      employee: "",
      id: "",
      PayByZhyzlData: {
        entId: "",
        humanEntId: "",
        id: "",
        salaryDetailsIds: [],
        remark: "",
        code: "",
      },
      forms: {
        humanEntId: "",
        entId: "",
        remarks: "",
        remark: "",
        code: "",
      },
      rules: {
        remarks: [
          {
            required: true,
            message: "请输入留言",
            trigger: ["blur"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入支付密码",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  methods: {
    lines5() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[5].prepend(lswt_2);
    },
    lines6() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[6].prepend(lswt_2);
    },
    lines7() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[7].prepend(lswt_2);
    },
    lines8() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[8].prepend(lswt_2);
    },
    // 薪资代发审批流列表
    getCtivitie() {
      if (this.id != "undefined") {
        instance({
          method: "post",
          url: `/v1/xAppPro/getAppProList/${this.id}`,
        })
          .then((res) => {
            if (res.success) {
              this.activities = res.data;
            }
          })
          .catch((err) => {});
      }
    },
    remarkList() {
      instance({
        method: "post",
        url: `/v1/xLeaveMess/getLeaveMessList/${this.$route.query.id}`,
      })
        .then((res) => {
          if (res.success) {
            this.remarksList = res.data;
          }
        })
        .catch((err) => {});
    },
    // 留言备注
    CommentOfMessage() {
      this.CommentMessage = true;
    },
    CommentOfMessages() {
      this.CommentMessage = false;
      this.forms.remarks = "";
    },
    // 新增-留言
    addLeaveWord() {
      instance({
        method: "post",
        url: "/v1/xLeaveMess/saveLeaveMess",
        data: {
          id: this.$route.query.id,
          remarks: this.forms.remarks,
        },
      })
        .then((res) => {
          if (res.success) {
            this.remarkList();
            this.CommentOfMessages();
          }
        })
        .catch((err) => {});
    },
    messageDelete(val) {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.$confirm("此操作将永久删除该留言, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            instance({
              method: "post",
              url: "/v1/xLeaveMess/deleteLeaveMess",
              data: val.id,
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.remarkList();
                }
              })
              .catch((err) => {});
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }, 500);
    },
    accountBalance() {
      instance({
        method: "post",
        url: `/v1/pAcctSon/proAccountById/${this.details.humanEntId}/${this.details.entId}`,
      })
        .then((res) => {
          if (res.success) {
            this.account = res.data;
            this.account.balance = this.account.balance.toFixed(2);
          }
        })
        .catch((err) => {});
    },
    submit() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.present = true;
        setTimeout(() => {
            this.present = false;
          }, 60000);
        instance({
          method: "post",
          url: "/v1/xReplaceProvide/approvalIssuanceBehalf",
          data: {
            id: this.details.id,
            status: 1,
            remarks: this.forms.remarks,
          },
        })
          .then((res) => {
            if (res.success) {
              bus.$emit("sends");
              this.relevancyClose();
              this.relevancy = false;
              setTimeout(() => {
                this.present = false;
              }, 1000);
            } else {
              this.present = false;
            }
          })
          .catch((err) => {
            this.present = false;
          });
      }, 500);
    },
    examine() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.audit = true;
        setTimeout(() => {
            this.audit = false;
          }, 60000);
        instance({
          method: "post",
          url: "/v1/xReplaceProvide/approvalIssuanceBehalf",
          data: {
            id: this.details.id,
            status: this.radioTreaty,
            remarks: this.forms.remarks,
          },
        })
          .then((res) => {
            if (res.success) {
              bus.$emit("sends");
              this.configurationClose();
              this.deploy = false;
              setTimeout(() => {
                  this.audit = false;
                }, 1000);
            } else {
              this.audit = false;
            }
          })
          .catch((err) => {
            this.audit = false;
          });
      }, 500);
    },
    relevancyShow() {
      this.forms.remarks = "";
      this.unpaid();
      this.accountBalance();
      this.relevancy = true;
    },
    relevancyClose() {
      this.relevancy = false;
    },
    configurationshow() {
      this.forms.remarks = "";
      this.radioTreaty = 2;
      this.unpaid();
      this.accountBalance();
      this.deploy = true;
    },
    configurationClose() {
      this.deploy = false;
    },
    paymentShow() {
      this.forms.code = "";
      this.forms.remark = "";
      this.unpaid();
      this.accountBalance();
      this.payment = true;
    },
    unpaid() {
      instance({
        method: "post",
        url: "/v1/xReplaceProvide/getDetailsBePaid",
        data: this.$route.query.id,
      })
        .then((res) => {
          if (res.code == 200) {
            this.paidList = res.data;
            this.paidList.grantMon = Number(this.paidList.grantMon).toFixed(2);
          }
        })
        .catch((err) => {});
    },
    paymentClose() {
      this.payment = false;
    },
    // 点击发送短信
    // send() {
    //   if (this.timers) {
    //        clearTimeout(this.timers);
    //   }
    //   this.timers = setTimeout(() => {
    //     if(Number(this.details.grantMon) > Number(this.account.balance) || Number(this.details.grantMon) == 0){
    //      this.$message.error("代发总金额不能大于账户余额且不能等于零！");
    //     }else{
    //       if (
    //         /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/.test(
    //           localStorage.getItem('phone')
    //         )
    //       ) {
    //         this.verificationCode();
    //         this.timeBuild();
    //       } else {
    //         this.$message.error("发送失败");
    //       }
    //     }
    //   }, 500);
    //     },
    //      // 支付 获取验证码
    //     verificationCode() {
    //       instance({
    //         method: "post",
    //         url: "/v1/xReplaceProvide/payPayrollSendCode",
    //         data: {
    //           grantMon: this.details.grantMon,
    //           grantNum: this.details.grantNum,
    //           phone:localStorage.getItem('phone'),
    //           salaryName: this.details.salaryName,
    //         },
    //       })
    //         .then((res) => {
    //           if(res.success){
    //             this.$message.success("发送成功");
    //           }
    //         })
    //         .catch((err) => {});
    //     },
    //     // 读秒倒计时
    //     timeBuild() {
    //       this.timer = setInterval(() => {
    //         if (this.time < 0) {
    //           this.time = 60;
    //           this.isTimerSrop = false;
    //           clearInterval(this.timer);
    //         } else {
    //           this.isTimerSrop = true;
    //           this.msgCodeBtnText = this.time-- + "s";
    //         }
    //       }, 1000);
    //     },
    // 立即支付
    SubmitAuditPay() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        if (
          Number(this.details.grantMon) > Number(this.account.balance) ||
          Number(this.details.grantMon) == 0
        ) {
          this.$message.error("代发总金额不能大于账户余额且不能等于零！");
        } else {
          this.CloudDirectlyConnectedsMybank();
          // instance({
          //   method: "post",
          //   url: "/v1/sysSmsHistory/getVerificationCode",
          //   data: {
          //     phone: localStorage.getItem('phone'),
          //     code:this.forms.code,
          //     smsType:1
          //   },
          // })
          //   .then((res) => {
          //     if (res.code === 200) {
          //          this.payment = false
          //     }
          //   })
          //   .catch((err) => {});
        }
      }, 500);
    },
    // 平安银行  支付
    CloudDirectlyConnectedsMybank() {
      this.$refs.forms.validate(async (valid) => {
        if (valid) {
          this.$message.warning("正在处理请稍后！");
          this.toLoad = true;
          setTimeout(() => {
            this.toLoad = false;
          }, 60000);
          this.toLoadClose = true;
          setTimeout(() => {
            this.toLoadClose = false;
          }, 60000);
          this.PayByZhyzlData.entId = this.details.entId;
          this.PayByZhyzlData.id = this.$route.query.id;
          this.PayByZhyzlData.humanEntId = this.details.humanEntId;
          this.PayByZhyzlData.salaryDetailsIds = this.paidList.salaryDetailsIds;
          this.PayByZhyzlData.remark = this.forms.remark;
          this.PayByZhyzlData.code = this.forms.code;
          this.forms.code = "";

          // const PayByZhyzlData = Encrypt(JSON.stringify(this.PayByZhyzlData));
          instance({
            method: "post",
            url: "/v1/xSalaryDetails/singleSalaryPaymentOnBehalf",
            data: {
              ...this.PayByZhyzlData,
            },
          })
            .then((res) => {
              if (res.code == 200) {
                bus.$emit("sends");
                this.forms.code = "";
                this.$message.success(res.data);
                this.paymentClose();
                this.payment = false;
                setTimeout(() => {
                  this.toLoad = false;
                  this.toLoadClose = false;
                }, 1000);
              } else {
                this.toLoad = false;
                this.toLoadClose = false;
              }
            })
            .catch((err) => {
              this.toLoad = false;
              this.toLoadClose = false;
            });
        }
      });
    },
  },
  mounted() {
    this.lines5();
    this.lines6();
    this.lines7();
    this.lines8();
    this.remarkList(), (this.employee = localStorage.getItem("employee"));
    this.id = this.$route.query.id;
    bus.$on("send", (valuation) => {
      this.details = JSON.parse(valuation);
      this.getCtivitie();
    });
  },
};
</script>

<style lang="less" scoped>
.tinymceSchedule_content {
  // height:100vh ;
  padding: 20px;
  background-color: #fff;
  min-width: 330px;
  .tinymceSchedule_title {
    width: 100%;
    border-bottom: 1px solid #ececec;
    display: flex;
    height: 40px;
    p:first-child {
      width: 3px;
      height: 13px;
      background: #6591f1;
      margin-top: 5px;
    }
    p:last-child {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 10px;
    }
  }
  .timeline {
    width: 100%;
    padding: 20px 10px;
    height: 640px;
    overflow: auto;
    .timelinetitle {
      span {
        color: #333;
        font-size: 16px;
        margin-top: 5px;
        font-weight: 500;
        position: relative;
        top: -8px;
      }
      img {
        position: relative;
        top: -2px;
        width: 23px;
        height: 23px;
      }
    }
    .timelineCon {
      padding: 20px;
      background: #f9f9f9;
      border-left: 3px solid #6591f1;
      p {
        line-height: 40px;
        span {
          color: #666;
          font-size: 14px;
          font-weight: 400;
        }
        img {
          position: relative;
          top: 4px;
        }
      }
    }

    .btn {
      width: 100%;
      margin-top: 10px;
      background: #f9f9f9;
      padding: 20px 25px;
      border-left: 3px solid #6591f1;
    }
    .noData {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
        height: 120px;
      }
    }
  }
  .timeline::-webkit-scrollbar {
    width: 5px;
    background: #e1e1e1;
    border-radius: 10px;
  }
  .timeline::-webkit-scrollbar-thumb {
    background: #b6b6b6;
    border-radius: 10px;
  }
  .comment {
    height: 400px;
    overflow: auto;
    .commentName {
      height: 40px;
      background: #f2f4fc;
      color: #4070f9;
      text-align: center;
      line-height: 40px;
    }
    .commentMain {
      padding: 20px 0px 20px 10px;
      .commentMainBody {
        width: 100%;
        height: 280px;
        overflow: auto;
        .leaveWord {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          i {
            font-size: 16px;
            color: red;
            font-weight: 600;
          }
          .staff {
            position: relative;
            top: -5px;
          }
          .expurgate {
            font-size: 14px;
            font-weight: 400;
            color: #e84b4d;
            cursor: pointer;
          }
        }
        .content {
          background: #fff;
          padding: 10px 20px;
          border: 1px solid #e5e5e5;
          margin-top: 10px;
        }
        .noData {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 50%;
            height: 120px;
          }
        }
      }
      .messageButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        position: relative;
        top: 10px;
      }
      .commentMainBody::-webkit-scrollbar {
        width: 5px;
        background: #e1e1e1;
        border-radius: 10px;
      }
      .commentMainBody::-webkit-scrollbar-thumb {
        background: #b6b6b6;
        border-radius: 10px;
      }
    }
  }
  // /deep/ .el-button--small{
  //   width: 200px !important;
  // }

  .pop-up {
    .line {
      height: 1px;
      background: #ececec;
      margin: 10px 0 30px;
    }
    .lines {
      height: 1px;
      background: #ececec;
    }
    /deep/ .el-dialog__title {
      padding-left: 14px;
      // border-left: #6591F1 3px solid;
      font-size: 16px;
    }
    /deep/ .el-form-item__label {
      font-weight: 400;
      color: #666666;
    }
    /deep/ .el-button {
      font-size: 12px;
      font-weight: 400;
      color: #999;
    }
    /deep/ .el-button--primary {
      color: #fff;
    }
    .createForm_button {
      /deep/ .el-button--primary {
        font-size: 14px;
      }
    }
    /deep/ .has-gutter {
      .cell {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        padding-left: 30px;
      }
    }
    /deep/ .el-dialog__body {
      padding: 0px 40px 20px;
    }
    /deep/ .el-dialog__footer {
      text-align: left;
      padding: 10px 40px 40px;
    }
    /deep/ .el-dialog__header {
      padding: 40px 40px 10px;
      .el-dialog__headerbtn {
        top: 40px;
        right: 40px;
      }
    }
    .popContent {
      padding: 0 26px 0 30px;
      .consequence {
        height: 200px;
        display: flex;
        justify-content: center;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          text-align: center;
        }
        .result {
          line-height: 100px;
        }
        .jhs_defeatedList {
          width: 100%;
          height: 330px;
          position: relative;
          top: 30px;
          /deep/ .el-table {
            ::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
    .popContents {
      padding: 0px;
      .arguments {
        padding-left: 30px;
        p {
          font-weight: 500;
          color: #333333;
          margin-bottom: 20px;
        }
      }
      .components-forms {
        padding: 0;
        .pagination {
          margin: 20px 0;
        }
      }
    }
    .Eclipse {
      background: #f7f9fe;
      height: 36px;
      margin-right: 22px;
      padding: 8px 30px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  /deep/ .el-input__inner {
    background: #f7f8fa;
    border: none;
  }
  /deep/ .el-button--danger {
    background: #e84b4d;
  }
}
</style>